@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,600;9..40,700&display=swap");

:root {
  --bg-color: #eeeded;
  --accent: #0d1282;
  --accent2: #d71313;
  --accent3: #f0de36;
  --darkaccent2: #a50f0e;
  --black: #000;
  --bs-gutter-x: 3rem;
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "DM Sans", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  background-color: var(--bg-color);
  margin: 0;
  padding: 0;
}
.h1,
.h2 {
  font-size: 3rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.75rem;
}

.h5 {
  font-size: 1.5rem;
}

.h6 {
  font-size: 1.25rem;
}
img {
  max-width: 100%;
}
p {
  font-size: 1rem;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.container {
  max-width: 100%;
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  //@media (min-width:1400px) {
  //max-width: 1320px;
  //}
  .row {
    display: flex;
    flex-wrap: wrap;
  }
}
.mainWrapper {
  header {
    padding: 0.5rem 0;
    @media (min-width: 992px) {
      padding: 1rem 0;
    }
    background-color: #fff;
    box-shadow: 0 1.125rem 2.25rem -1.188rem rgba(0, 0, 0, 0.4);
    position: relative;
    z-index: 2;
    .logo-wrapper {
      a {
        img {
          max-width: 2rem;
          @media (min-width: 992px) {
            max-width: 3rem;
          }
        }
      }
    }
  }
  .hero-section {
    background-color: var(--bg-color);
    padding: 3rem 0;
    @media (min-width: 992px) {
      padding: 4rem 0;
    }
    .container {
      .row {
        .headline-wrapper {
          width: 100%;
          flex: 0 0 auto;
          text-align: center;
          @media (min-width: 992px) {
            margin-bottom: 2rem;
            padding: 0 1rem;
          }
          h1 {
            color: var(--accent);
            margin-bottom: 1rem;
            line-height: 120%;
            font-size: 1.5rem;
            font-weight: bold;
            @media (min-width: 992px) {
              font-size: 2rem;
            }
            @media (min-width: 1200px) {
              font-size: 2.5rem;
            }
          }
        }
        .sub-hero-section {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .left-side {
            flex: 0 0 auto;
            width: 100%;
            text-align: center;
            @media (min-width: 992px) {
              width: 50%;
              padding: 0 1rem;
              text-align: left;
            }
            h2 {
              margin-bottom: 1rem;
              font-size: 1.25rem;
              font-weight: 500;
              @media (min-width: 1200px) {
                font-size: 1.5rem;
              }
            }
            p {
              margin-bottom: 1rem;
              font-size: 1rem;
            }
            .desktop-cta {
              display: none;
              text-align: center;
              @media (min-width: 992px) {
                display: block;
                flex: 0 0 auto;
                width: 100%;
                margin: 1rem 0;
                a {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-decoration: none;
                  font-size: 1.5rem;
                  font-weight: 700;
                  background-color: var(--accent2);
                  color: #fff;
                  padding: 0.5rem 1rem;
                  border-radius: 100px;
                  img {
                    max-width: 40px;
                    margin-left: 0.5rem;
                  }
                  transition: all 0.2s ease-in-out;
                  &:hover,
                  &:focus {
                    background-color: var(--darkaccent2);
                  }
                }
                span {
                  display: block;
                  @media (min-width: 992px) {
                    margin-top: 1rem;
                  }
                  font-size: 1rem;
                }
              }
            }
          }
          .right-side {
            flex: 0 0 auto;
            text-align: center;
            width: 100%;
            @media (min-width: 992px) {
              width: 50%;
              padding: 0 1rem;
              text-align: left;
            }
          }
          .mobile-cta {
            flex: 0 0 auto;
            width: 100%;
            margin: 1rem 0;
            text-align: center;
            @media (min-width: 992px) {
              text-align: center;
              display: none;
            }
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              font-size: 1.5rem;
              font-weight: 700;
              background-color: var(--accent2);
              color: #fff;
              padding: 0.5rem 1rem;
              border-radius: 100px;
              img {
                max-width: 40px;
                margin-left: 0.5rem;
              }
            }
            span {
              display: block;
              margin-top: 1rem;
              font-size: 1rem;
            }
          }
        }
        .logo-strip {
          flex: 0 0 auto;
          width: 100%;
          margin: 1rem 0;
          .logoitem {
            max-width: 38.75rem;
            margin: 1rem auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            gap: 0.5rem;
            @media (min-width: 960px) {
              max-width: 50rem;
            }
            img {
              flex: 0 0 auto;
              width: 25%;
              margin-bottom: 0.25rem;
              @media (min-width: 960px) {
                width: 15%;
              }
            }
          }
        }
        .left-side {
          flex: 0 0 auto;
          width: 100%;

          @media (min-width: 992px) {
            padding: 0 1rem;
          }
          .question {
            position: relative;
            background-color: #fff;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            padding: 2rem 1.5rem;
            @media (min-width: 992px) {
              padding: 3rem 1.5rem;
            }
            border-radius: 18px;
            text-align: center;
            margin: 1rem auto;
            max-width: 580px;
            width: 100%;
            h2 {
              color: var(--accent);
              font-size: 1.4rem;
              line-height: 120%;
              font-weight: 500;
            }
            a {
              display: block;
              margin: 1rem 0;
              background-color: var(--accent2);
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              padding: 1rem;
              font-size: 1.5rem;
              border-radius: 8px;
              box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            }
            input {
              display: block;
              width: 100%;
              margin: 1rem 0;
              padding: 1rem;
              border-radius: 8px;
              border: 1px solid #e5e5e5;
              font-size: 1rem;
              -moz-appearance: textfield;
              appearance: textfield;
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
          .congrats {
            margin: 1rem auto;
            max-width: 580px;
            width: 100%;
            background-color: #fff;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            padding: 1.25rem;
            @media (min-width: 992px) {
              padding: 2rem;
            }
            border-radius: 18px;
            text-align: center;
            h2 {
              color: var(--accent);
              margin-bottom: 0.5rem;
              font-size: 1.4rem;
              line-height: 120%;
              @media (min-width: 992px) {
                font-size: 2rem;
              }
            }
            a {
              display: block;
              margin: 1rem 0;
              background-color: var(--accent2);
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              padding: 1rem;
              font-size: 1.5rem;
              border-radius: 8px;
              box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
              transition: all 0.2s ease-in-out;
              &:hover,
              &:focus {
                background-color: var(--darkaccent2);
              }
            }
            p {
              span {
                color: #0d1282;
                font-weight: bold;
              }
            }
            img {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
  .section1 {
    background-color: #fff;
    padding: 3rem 0;
    @media (min-width: 960px) {
      padding: 4rem 0;
    }
    .section-1-content {
      flex: 0 0 auto;
      width: 100%;
      text-align: center;
      h1 {
        color: var(--accent2);
        margin-bottom: 1rem;
        line-height: 120%;
        font-size: 1.75rem;
        @media (min-width: 992px) {
          font-size: 2rem;
        }
        @media (min-width: 1200px) {
          font-size: 2.5rem;
        }
      }
      h2 {
        font-size: 1.25rem;
        font-weight: 500;
      }
      .item-benefits {
        margin: 2rem 0;
        //padding: 0 1rem;
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            flex: 0 0 auto;
            width: 50%;
            padding: 0 0.5rem;
            margin-bottom: 1rem;
            @media (min-width: 960px) {
              width: 25%;
            }
            .benefit {
              justify-content: center;
              display: flex;
              align-items: center;
              background-color: var(--accent2);
              border-radius: 5px;
              box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.12);
              padding: 1rem;
            }
            img {
              max-width: 46px;
              max-height: 46px;
            }
            p {
              margin-left: 0.5rem;
              font-weight: bold;
              color: #fff;
            }
          }
        }
      }
      .cta-holder {
        max-width: 580px;
        margin: 1rem auto;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          font-size: 1.5rem;
          font-weight: 700;
          background-color: var(--accent2);
          color: #fff;
          padding: 0.5rem 1rem;
          border-radius: 100px;
          img {
            max-width: 40px;
            margin-left: 0.5rem;
          }
          transition: all 0.2s ease-in-out;
          &:hover,
          &:focus {
            background-color: var(--darkaccent2);
          }
        }
        span {
          font-size: 1rem;
        }
      }
    }
  }
  .testimonial {
    text-align: center;
    padding: 3rem 0;
    @media (min-width: 960px) {
      padding: 4rem 0;
    }
    img {
      max-width: 100%;
      @media (min-width: 992px) {
        max-width: 580px;
      }
      margin: 1rem auto;
    }
    .testimonial-headline {
      flex: 0 0 auto;
      width: 100%;
      h1 {
        color: var(--accent2);
        margin-bottom: 1rem;
        line-height: 120%;
        font-size: 1.75rem;
        @media (min-width: 992px) {
          font-size: 2rem;
        }
        @media (min-width: 1200px) {
          font-size: 2.5rem;
        }
      }
    }
    .testimonial-content-holder {
      flex: 0 0 auto;
      width: 100%;
      h3 {
        font-weight: 500;
      }
    }
    .testimonial-list {
      display: flex;
      margin-top: 2rem;
      flex-wrap: wrap;
      .testimonial-item {
        margin-bottom: 1rem;
        flex: 0 0 auto;
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 1rem;
        @media (min-width: 960px) {
          width: 50%;
        }
        .testimonial-inner {
          background-color: #fff;
          padding: 1.56rem;
          text-align: left;
          box-shadow: 0rem 0rem 1.25rem rgba(0, 0, 0, 0.05);
          .testimonial-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            .testimonial-left {
              img {
                max-width: 50%;
                border-radius: 10px;
              }
            }
            .testimonial-right {
              .star-rating {
                display: flex;
                img {
                  width: 150px;
                }
              }
            }
          }
          .testimonial-bottom {
            h3 {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.error {
  color: red !important;
}
